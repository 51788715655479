
  import { mapGetters } from "vuex";

  import BannerMainPage from "../mainPage/BannerMainPage";
  import ContentMainPage from "../mainPage/ContentMainPage";
  import ButtonComponent from "../common/ButtonComponent";
  import TrackComponent from "../common/TrackComponent";

  export default {
    name: "MainPage",
    components: { TrackComponent, ButtonComponent, ContentMainPage, BannerMainPage },
    computed: {
      ...mapGetters("tracks", ["trackList"])
    },
    async mounted() {
      await this.$store.dispatch("tracks/getTrackList", { size: 10 });
    }
  };
